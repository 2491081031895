@import "../../styles/colors";

.wheel-wrapper {
  display: flex !important;
  max-width: 100% !important;
  margin: 0 !important;
  flex-direction: column;
  padding-bottom: 0 !important;
  position: relative;
  flex-grow: 1;

  &.padding {
    padding-left: 50px !important;
    padding-right: 0 !important;
  }
}

.wheel-content {
  display: flex;
  position: relative;
  flex-grow: 1;
  overflow-y: hidden;
  width: 100%;

  .description-link {
    background-color: transparent;
    border: none;
    color: $grey-4;
    display: inline-block;
    width: fit-content;
    cursor: pointer;
    font-size: 18px;
    text-decoration: underline;
    padding: 0;
    margin-top: 5px;
    text-align: start;

    &:hover {
      color: $grey-1;
    }
  }

  .wheel-info-wrapper {
    display: flex;
    flex-direction: column;
  }

  .bracket-wrapper {
    margin-top: 10px;
    width: 100%;
    flex-basis: 56%;
    //height: 50%;
    max-width: 800px;
    max-height: 56%;
    flex-grow: 1;
    //overflow: hidden;
  }

  .bracket-sub-wrapper {
    position: relative;
    height: 100%;
    max-width: 95%;
    max-height: 100%;
    border-radius: 10px;
    border: 2px solid $grey-4;
    background-color: $grey-11;
    overflow: hidden;

    .bracket-controls {
      position: absolute;
      top:5px;
      left: 5px;
      display: flex;
      align-items: flex-end;
      z-index: 10;

      .hint {
        font-size: 14px;
        font-weight: 400;
      }

      .fullscreen-button {
        background-color: $grey-11;
        margin-right: 5px;
        flex-shrink: 0;
      }

      .current-game-button {
        background-color: $grey-11;
        margin-right: 5px;
        flex-shrink: 0;
      }
    }

    &.fullscreen {
      position: absolute;
      height: 98%;
      width: 100%;
      max-height: 98%;
      top: 0;
      left: 0;
      z-index: 10;
    }
  }

  .wheel-controls {
    color: $grey-2;
    display: flex;
    align-items: stretch;

    &.shrink {
      height: 40%;
      max-height: 40%
    }

    .settings {
      display: flex;
      width: 370px;
      flex-direction: column;
      overflow-x: hidden;
      overflow-y: auto;
      padding: 0 15px;

      .upload-wheel-image {
        align-self: center;
        margin-top: 15px;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }

    &-button {
      height: 40px;
    }

    &-row {
      display: flex;
      align-items: center;
    }

    &-input {
      margin-left: 15px !important;
      margin-right: 5px !important;
      width: 110px;
    }

    &-tip {
      position: relative;
      font-size: 1.1rem !important;
      top: 8px;

      &.hint {
        width: 100%;
        font-size: 14px !important;
        top: 0;
      }

      &.md {
        width: 210px;
        font-size: 16px;
        align-self: start;
        margin-bottom: 10px;
      }

      &.lg {
        width: 330px;
        font-size: 16px;
        align-self: start;
        margin-bottom: 10px;
      }

      &.low {
        font-size: 16px;
        display: block;
      }
    }

    .MuiSlider-valueLabel {
      font-weight: 400;
    }

    &-checkbox {
      display: flex;
      align-items: center !important;

      .MuiFormControlLabel-label {
        margin-top: 3px;
        font-weight: 400;
        font-size: 1rem;
        letter-spacing: normal;
      }
    }
  }
}
