@import "../../styles/colors";

.box-container {
  display: flex;
  flex-direction: column;
  padding: 25px;
  border: 1px solid $grey-7;
  align-items: center;
}

.row-ap {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.col-ap {
  display: flex;
  flex-direction: column;
}

audio {
  flex-grow: 1;
}

.preset {
  padding: 15px;
  justify-content: space-between;
}

.presets-list {
  max-height: 85vh;
  overflow-y: auto;
}