.auc-settings {

  &-list {
    margin-bottom: 15px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &-option {
    margin-left: 15px;

    .MuiSelect-selectMenu {
      display: flex;
    }

    p {
      margin-right: 5px;
    }
  }

  &-row {
    align-items: center;
    margin-bottom: 10px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.upload-background {
  margin-right: 15px !important;
  margin-left: 10px !important;
}

.settings {
  .MuiFormControlLabel-labelPlacementStart {
    margin: 0;
    align-items: center !important;
  }

  .MuiFormControlLabel-label {
    width: 380px;
  }

  .field {
    margin-right: 7px;

    &.sm {
      width: 60px;
    }

    &.md {
      width: 80px;
    }

    &.lg {
      width: 150px;
    }

    &.xlg {
      width: 350px;
    }

    input {
      padding: 0 15px;
      height: 2.6rem;
    }
  }
}
