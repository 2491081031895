$grey-1: #f0f0f0;
$grey-2: #DCDCDC;
$grey-3: #D3D3D3;
$grey-4: #C0C0C0;
$grey-5: #A9A9A9;
$grey-6: #808080;
$grey-7: #696969;
$grey-8: #616161;
$grey-9: #505050;
$grey-10: #424242;
$grey-11: #343434;
$grey-12: #303030;

$background-main: #242424;
$background-second: #2d2d2d;

$yellow: #ffeb3b;

$blue-gray-1: #b0bec5;
$blue-gray-2: #78909c;
$blue-gray-3: #455a64;
$blue-gray-4: #263238;
$blue-gray-5: #48696d;
$blue-gray-6: #2f6b7d;

$red-1: #8C4F4F;
$red-2: #BB9C9C;
$red-3: #C55454;
$red-4: #f48fb1;
$red-5: #ff3c3c;

$green-1: #6fdd6f;
$green-2: #6cc16c;

$da: #f39b4b;
$twitch: #b285ff;