@import "../../../styles/colors";

.slots-preset-input {
  &-hint {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $grey-4;
    font-family: 'Helvetica Neue', Helvetica, "Roboto Light", sans-serif;
    margin-top: 10px;
    padding: 3px;
    font-size: 1.05rem;
  }
}

.save-slots-checkbox {
  align-items: center !important;
  margin-top: 10px;
}
