@import "../../styles/colors";

.table-of-contents {
  padding: 20px 40px;
  width: 700px;

  .content {
    display: flex;
    flex-direction: column;
    top: 60px;
    position: fixed;
  }

  .title {
    color: $grey-3;
    font-weight: 400;
    font-size: 20px;
  }

  &-list {
    border-left: 1px solid $grey-6;
    padding-left: 20px;

    .paragraph {
      list-style: none;
      a {
        color: $grey-3;
        line-height: 26px;
        font-size: 16px;
        text-decoration: none;
        font-weight: 400;
        //padding: .125rem 1.5rem;

        &:hover {
          color: #ffffff;
        }
      }
    }
  }
}
