@import "../../../styles/colors";

.search-form {
  display: flex;
  border-radius: 5px;
  border: 1px solid $grey-6;
  margin-bottom: 10px;

  &-input {
    flex-grow: 1;
  }

  &-icon {
    padding: 10px !important;
  }
}

.trailer {
  .trailer-loading {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &-spinner {
      width: 100px !important;
      height: 100px !important;
      margin-top: 10%;
    }
  }

  .trailers-list {

  }
}

.interaction {
  .youtube-container {
    pointer-events: none;
  }
}