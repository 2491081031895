.help-page {
  .content {
    display: flex;
    align-items: flex-start;
  }
  .MuiTypography-body1 {
    max-width: 800px;
    font-size: 19px;
    margin-bottom: 10px;
    font-weight: 300;
  }

  .MuiTypography-body2 {
    max-width: 800px;
    color: #ddd;
    display: list-item;
    margin-left: 20px;
    font-size: 17px;
  }
}
