@import "../../../styles/colors";

.username {
  margin-left: 10px;
  color: $red-4;
  font-weight: 400;
  font-size: 22px;
}

.open-rewards-button {
  width: 250px;

  &.close {
    color: white;
    border-color: $grey-6;

    &:hover {
      border-color: $grey-4;
    }
  }
}

.rewards {
  .ag-center-cols-container, .ag-center-cols-clipper {
    min-height: 50px !important;
  }
}

.twitch-login-button {
  background-color: #7c43d0 !important;
  color: #F1F1F1 !important;

  &:hover {
    background-color: #6c38b8 !important;
  }
}