@import "../../../styles/colors";

.options-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.options {
  display: flex;
  align-items: center;
  gap: 15px;

  a {
    text-decoration: none;
  }

  .da-link {
    color: #aaa;
    letter-spacing: 1.5px;
    text-decoration: underline;
    font-size: 1.1rem;
    font-family: Arial;

    &.gitlab {
      width: 61px;
    }

    &:hover {
      color: white;
    }
  }

  &-menu {
    position: absolute;
    right: 35px;
    bottom: 90px;
    padding: 20px;
  }

  .button {
    border-color: rgba(#fff, 0.4) !important;

    &.restore {
      margin-right: 12px;
    }
  }
}

.confirm-restore {
  .MuiDialogContentText-root {
    margin-bottom: 0;
  }

  .MuiTypography-h6 {
    margin-bottom: 0;
  }

  .save-current-slots {
    .MuiCheckbox-root {
      padding: 0 !important;
      margin-right: 5px;
    }

    .MuiFormControlLabel-label {
      margin-top: 3px;
      font-weight: 400;
      font-size: 0.9rem;
      letter-spacing: normal;
      color: $grey-4;
    }
  }
}

.compact-view-checkbox {
  align-items: center !important;

  .MuiFormControlLabel-label {
    font-size: 1.1rem;
  }
}