.history-table {
  width: 100%;
  min-height: 300px;

  .MuiTypography-body2 {
    margin-bottom: 0;
    font-weight: 400;
  }

  .MuiDataGrid-mainGridContainer {
    &>div:first-of-type {
      display: none !important;
    }
  }

  .status {
    &.deleted {
      color: #F08080;
    }

    &.processed {
      color: #AED581;
    }
  }
}