.da-login-button {
  background-color: #F57D07 !important;
  color: #F1F1F1 !important;

  &:hover {
    background-color: #cb6a0a !important;
  }

  &:disabled {
    background-color: #746d67 !important;

    .da path {
      fill: #c4bbb4;
    }
  }
}
