.loading-page {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &-spinner {
    width: 100px !important;
    height: 100px !important;
    margin-bottom: 20px;
  }
}