@import "../../../styles/colors";

.slot-wrapper {
  display: flex;
  align-self: stretch;
  align-items: center;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  .slot {
    display: flex;
    flex-grow: 1;
    height: 40px;
    padding: 10px;
    border: 1px solid transparent;
    border-radius: 10px;
    align-items: center;

    input {
      font-size: 19px;
      padding: 11px 12px;
    }

    &-chance {
      font-weight: 400;
      margin-right: 15px;
      font-size: 18px;
    }

    &-add-extra {
      padding: 7px;
      margin: 5px;
    }

    &-crown {
      position: absolute;
      width: 22px;
      height: 22px;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    &-index {
      font-size: 1.1em;
      width: 35px;
    }

    &-fast-index {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1em;
      margin-right: 10px;
      width: 45px;
      height: 80%;
      border: 2px solid $blue-gray-2;
      border-radius: 10px;
      background-color: rgba($blue-gray-5, .3);
    }

    &-name {
      flex-grow: 1;
      margin-right: 25px;
    }

    &-money {
      width: 110px;
      padding-left: 5px;
    }
  }

  .delete-button {
    opacity: 0.9;
    width: 50px;
    height: 50px;
  }

  &:hover {
    .delete-button {
      opacity: 1;
    }
  }

  &.custom-background {
    .slot {
      .MuiOutlinedInput-notchedOutline {
        border-color: $grey-5;
      }

      &-input {
        background-color: rgba(0, 0, 0, 0.2);
      }
    }
  }
}

.slots-column.dragging {
  .slot-wrapper {
    .slot {
      background-color: $grey-11;
      border-color: $grey-9;

      &.drag-over {
        background-color: $blue-gray-3;
        border-color: $blue-gray-2;

        &.remove-cost {
          background-color: $red-1;
          border-color: $red-2;
        }
      }
    }

    &.custom-background {
      .slot {
        background-color: rgba(100, 100, 100, 0.5);
        border-color: $grey-5;

        .slot-input {
          background-color: transparent;
        }

        &.drag-over {
          background-color: rgba($blue-gray-3, 0.7);
          border-color: $blue-gray-2;

          .slot-input {
            background-color: transparent;
          }

          &.remove-cost {
            background-color: rgba($red-1, .7);
            border-color: $red-2;
          }
        }
      }
    }
  }
}
