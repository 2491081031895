.save-load-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;

  .saves-container {
    max-height: 60vh;
    overflow: auto;
  }

  .controls {
    display: flex;
    justify-content: center;
    margin-top: 15px;
  }
}