.se-login-button {
  background-color: #243264 !important;
  color: #F1F1F1 !important;

  &:hover {
    background-color: #1A2759 !important;
  }

  &:disabled {
    background-color: #746d67 !important;

    .se path {
      fill: #c4bbb4;
    }
  }
}
