.alerts-container {
  position: absolute;
  z-index: 1000;
  bottom: 10px;
  margin-left: 30px;

  .MuiAlert-root {
    margin-top: 10px;
    padding: 0 16px;
  }
}

.example-enter {
  opacity: 0;
  height: 0;
  overflow: hidden;
}

.example-enter-active {
  opacity: 1;
  height: 36px;
  transition: opacity 250ms ease-in, height 150ms linear !important;
}

.example-exit {
  opacity: 1;
}

.example-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-in !important;
}